*{
    margin: 0;
    padding: 0;
}
.navbar{
    display: flex;
    height: 2rem;
    align-items: center;
    background: linear-gradient(90deg, #2540ad 1.18%, #6b37cc 100%);
    color: white;
    padding: 0.75rem;
    gap: 2rem;
    padding: 1.5rem 3rem;

}

.nav-img{
    height: 100%;
}

.form-holder{
    max-width: 50%;
    margin: auto;

}

.form{
    
  
    width: 700px;
    margin: auto;
    margin-top: 2rem;
    display: grid;
    padding: 2rem;
    gap:1.5rem;
    grid-template-areas: 
    "a b"
    "c c";
}

.input-top{
    text-indent:0.5rem;
    border-radius: 0.5rem;
    font-size: 1.5rem;
    padding: 0.5rem;
    grid-area: a;
}
.input-bottom{
    
    text-indent:0.5rem;
    border-radius: 0.5rem;
    font-size: 1.5rem;
    padding: 0.5rem;
    grid-area: b;
}
.form-button{
    
    max-width: 80%;
    margin: auto;
    background: linear-gradient(90deg, #2540ad 1.18%, #6b37cc 100%);
    color: white;
    border-radius: 0.5rem;
    border: none;
    font-size: 1.2rem;
    padding:0.6rem 0.7rem ;
    grid-area: c;
}
.meme {
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
    position: relative;
}
.meme-img{
  
    max-width: 50%;
    margin: auto;
    border-radius: 3px;
}
.meme-text {
    position: absolute;
    width: 80%;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    margin: 15px 0;
    padding: 0 5px;
    font-family: impact, sans-serif;
    font-size: 2em;
    text-transform: uppercase;
    color: white;
    letter-spacing: 1px;
    text-shadow:
        2px 2px 0 #000,
        -2px -2px 0 #000,
        2px -2px 0 #000,
        -2px 2px 0 #000,
        0 2px 0 #000,
        2px 0 0 #000,
        0 -2px 0 #000,
        -2px 0 0 #000,
        2px 2px 5px #000;
}

.bottom {
    bottom: 0;
}

.top {
    top: 0;
}

@media (max-width: 750px) {

    html{
        font-size:10px;
    }

    .form{
        width: 80%;
        margin-top: 3rem;
        display: grid;
        padding: 1.5rem;
        gap: 2.5rem;
        grid-template-areas: 
        "a a"
        "b b"
        "c c";
    }

    .input-top{
        
        text-indent:0.5rem;
        border-radius: 0.5rem;
        font-size: 2rem;
        padding: 0.5rem;
        grid-area: a;
    }
    .input-bottom{
        
        text-indent:0.5rem;
        border-radius: 0.5rem;
        font-size: 2rem;
        padding: 0.5rem;
        grid-area: b;
    }
    .form-button{
        max-width: 80%;
        margin: auto;
        background: linear-gradient(90deg, #2540ad 1.18%, #6b37cc 100%);
        color: white;
        border-radius: 0.5rem;
        border: none;
        font-size: 2rem;
        padding: 1rem;
        grid-area: c;
    }
    .meme {
        width: 80%;
        margin: auto;
        position: relative;
    }

    .meme-img{
        max-width: 100%;
    }
} 
